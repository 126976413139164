import './index.css';

const AdvantagesSection = () => {
  return (
    <section className="compliance">
        <div className='compliance__wrapper'>
            <div className="compliance__text-container">
                <p className="compliance__text compliance__text_paragraph_first">
                    The most immediately apparent advantage of stablecoin technology is its utility as a medium of exchange, effectively bridging the gap between fiat and cryptocurrency. By minimizing price volatility, stablecoins can achieve a utility wholly separate from the ownership of legacy cryptocurrencies. <span className='technology__title_pink'>Transactional freedom, security, and ease of transaction</span> are among the most important advantages of cryptocurrency.
                </p>
                <br/>
                <p className="compliance__text compliance__text_paragraph_first">
                    As their name suggests, stablecoins are inherently stable assets, making them a suitable store of value, which encourages their use in everyday transactions. Further, stablecoins improve the mobility of crypto assets throughout the ecosystem.
                </p>
                <br/>
                <p className="compliance__text compliance__text_paragraph_first">
                    Stablecoins point the way toward integrating traditional financial markets with the quickly evolving <a className='technology__title_pink' href='https://www.gemini.com/cryptopedia/glossary#decentralized-finance-de-fi' target='_blank'>decentralized finance (DeFi)</a> industry. As a force for market stability, stablecoins present a primary vehicle for cryptocurrency adoption in loan and credit markets, while inheriting much of the utility previously reserved for only fiat currency.
                </p>
            </div>
        </div>
    </section>
  )
}

export default AdvantagesSection;
