import './index.css';

const BenefitsSection = () => {
  return (
    <section className="compliance">
        <div className='compliance__wrapper'>
            <div className="compliance__text-container">
                <p className="compliance__text compliance__text_paragraph_first">
                    5 Top benefits of using a stablecoin:
                </p>
                <ul>
                    <li className="compliance__text compliance__text_paragraph_first">Faster speed</li>
                    <li className="compliance__text compliance__text_paragraph_first">Lower fees</li>
                    <li className="compliance__text compliance__text_paragraph_first">Borderless</li>
                    <li className="compliance__text compliance__text_paragraph_first">Transparent</li>
                    <li className="compliance__text compliance__text_paragraph_first">Programmable - Programmable money is powerful as it allows for money to be used for only the things it is intended for like food purchases and helping people adhering to budgets.</li>
                </ul>
            </div>
        </div>
    </section>
  )
}

export default BenefitsSection;
