import './index.css';

const UseCasesSection = () => {
  return (
    <section className="compliance">
        <div className='compliance__wrapper'>
            <div className="compliance__text-container">
                <p className="compliance__text compliance__text_paragraph_first">
                    Top 10 use cases for Stablecoins:
                </p>
                <ul>
                    <li className="compliance__text compliance__text_paragraph_first">Safe Haven Asset</li>
                    <li className="compliance__text compliance__text_paragraph_first">Trading</li>
                    <li className="compliance__text compliance__text_paragraph_first">Payments</li>
                    <li className="compliance__text compliance__text_paragraph_first">Remittance</li>
                    <li className="compliance__text compliance__text_paragraph_first">Payroll</li>
                    <li className="compliance__text compliance__text_paragraph_first">Settlement</li>
                    <li className="compliance__text compliance__text_paragraph_first">Escrow</li>
                    <li className="compliance__text compliance__text_paragraph_first">Lending</li>
                    <li className="compliance__text compliance__text_paragraph_first">Alternative Banking</li>
                    <li className="compliance__text compliance__text_paragraph_first">Powering decentralized applications</li>
                </ul>
            </div>
        </div>
    </section>
  )
}

export default UseCasesSection;
