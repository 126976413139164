import './Transparency.css';
import {useEffect, useState} from "react";
import { NavLink } from 'react-router-dom';
import {useRecoilState} from "recoil";
import Chart from "./chart";
import styles from './styles.module.scss';
import Modal from "./Modal";
import {ROUTES} from "../../constant/index";
import transparency from "../../services/endpoints/transparency";
import {collatRatioState} from "../../store/collatRartio";


function Transparency() {
  const [infoSummary, setInfoSummary] = useState([]);
  const [infoDetailed, setInfoDetailed] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [collatRatio, setCollatRatio] = useRecoilState(collatRatioState);

  const getTransparencyInfo = async () => {
    try {
      const res = await transparency.getTransparencyDetails();

      setInfoDetailed(Object.entries(res.data.data.detailed));
      setInfoSummary(res.data.data.summary);

    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getTransparencyInfo()
  }, []);

  const countCollatRatio = () => {
    setCollatRatio(infoSummary?.totalCollaterial && infoSummary?.totalCollaterial / infoSummary?.totalSupply * 100);
  }

  useEffect(() => {
    countCollatRatio();
  }, [infoSummary]);

  return (
    <section className={styles.container}>
      <div className={styles.chartSection}>
        <div>
          <h2 className="transparency__title">Transparency</h2>
          <ul className="transparency__table transparency__table_position_top">
            <li className="transparency__table__cell">
              <h3 className="transparency__table-cell-title title_size_big">Total QiUSD Supply</h3>
              <p className="transparency__table-cell-subtitle subtitle_size_big">$ {`${Number(infoSummary?.totalSupply).toLocaleString('en-US', {maximumFractionDigits: 0})}`}</p>
            </li>
            <li className="transparency__table__cell">
              <h3 className="transparency__table-cell-title">Authorized but not issued</h3>
              <p className="transparency__table-cell-subtitle">$ {infoSummary?.totalCollaterial && `${Number(infoSummary?.totalCollaterial - infoSummary?.totalSupply).toLocaleString('en-US', {maximumFractionDigits: 0})}`}</p>
            </li>
          </ul>
        </div>
        <div className={styles.chartBlock}>
          <Chart/>
          <div className={styles.diagramLinks}>
            {/*<NavLink to="/" className="diagram__link link_color_pink">*/}
            {/*  <span className="diagram__link-img diagram__link-img_type_gitdiff"></span>*/}
            {/*  <p className="diagram__link-text">Issuance Process</p>*/}
            {/*</NavLink>*/}
            <NavLink to={ROUTES.ISSUANCE_HISTORY} className="diagram__link link_color_pink">
              <span className="diagram__link-img diagram__link-img_type_clock"></span>
              <p className="diagram__link-text">Issuance History</p>
            </NavLink>
            {/*<NavLink to="/" className="diagram__link link_color_pink">*/}
            {/*  <span className="diagram__link-img diagram__link-img_type_brackets"></span>*/}
            {/*  <p className="diagram__link-text">Token Addresses</p>*/}
            {/*</NavLink>*/}
          </div>
        </div>
      </div>
      <div className="transparency__container transparency__container_position_bottom">
        <h3 className="transparency__total title_size_big">Total Collateral</h3>
        <p className="transparency__total-value subtitle_size_big">$ {`${Number(infoSummary?.totalCollaterial).toLocaleString('en-US', {maximumFractionDigits: 0})}`}</p>
        <ul className="transparency__table transparency__table_position_bottom">
          {infoSummary?.tokens && Object.entries(infoSummary?.tokens).map((elem, index) => (
              <li key={'key_' + index} className="transparency__table__cell transparency__table__cell_position_bottom">
                <h3 className="transparency__table-cell-title">{elem[0]}</h3>
                <p className="transparency__table-cell-subtitle">{`${Number(elem[1]).toLocaleString('en-US', {maximumFractionDigits: 0})}`}</p>
              </li>
          ))}
        </ul>
        <NavLink to="/" className="transparency__link link_color_pink">
          <span className="transparency__link-img"></span>
          <p className="transparency__link-text" onClick={() => setIsOpen(true)}>Collateral Details</p>
        </NavLink>
        <div className="transparency__ratio">
          <h3 className="transparency__ratio-title">Collat. Ratio</h3>
          <p className="transparency__ratio-value">{collatRatio}%</p>
          <p className="transparency__ratio-text">The stability and security of QiUSD
          are ensured by the over-collateralization of multiple mainstream stablecoins.</p>
        </div>
      </div>
      {infoDetailed.length &&
          <Modal data={infoDetailed} isOpen={isOpen} onClose={() => setIsOpen(false)}/>
      }
    </section>
  )
}

export default Transparency;
