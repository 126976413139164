import "./HistoryTable.css";
import useScreenWidth from "../../utils/useScreenWidth";
import LoadMoreButton from "../LoadMoreButton/LoadMoreButton";
import HistoryTableCell from "../HistoryTableCell/HistoryTableCell";
import { useState, useEffect } from 'react';

import {
  ISSUANCE_HISTORY_ELEMENTS,
  SCREEN_SIZE_DESKTOP,
  SCREEN_SIZE_MIDLE,
  DESKTOP_NUMBER_HISTORY_ELEMENTS,
  MIDLE_NUMBER_HISTORY_ELEMENTS,
  MOBILE_NUMBER_HISTORY_ELEMENTS, ALL_NUMBER_HISTORY_ELEMENTS
} from "../../constant/constants";
import issuanceApi from "../../services/endpoints/issuance";

function HistoryTable() {
  const [issuanceData, setIssuanceData] = useState([]);
  const [numberElements, setNumberElemets] = useState(0);
  const size = useScreenWidth();

  const buttonClassName = (
    `${(issuanceData.length>numberElements) ? 'load-more-button load-more-button_page_history' : 'load-more-button_disable' }`
    )

  useEffect(() => {
    handleWindowResize();
  }, [size]);

  const getIssuanceData = async () => {
    try {
      const res = await issuanceApi.getIssuanceHistory();

      setIssuanceData(res.data.data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getIssuanceData()
  }, []);

  function handleWindowResize () {
    if(size.width > SCREEN_SIZE_DESKTOP) {
      setNumberElemets(DESKTOP_NUMBER_HISTORY_ELEMENTS)
    }
    else if (size.width > SCREEN_SIZE_MIDLE) {
      setNumberElemets(MIDLE_NUMBER_HISTORY_ELEMENTS)
    } else {
    setNumberElemets(MOBILE_NUMBER_HISTORY_ELEMENTS)}
  }

  function handleClick() {
      setNumberElemets(ALL_NUMBER_HISTORY_ELEMENTS)
  }

  return (
    <>
    <ul className="history-table">
      {issuanceData.slice(0, numberElements).map((element) => (
        <HistoryTableCell element={element} />
      ))}
    </ul>
    <LoadMoreButton buttonClassName={buttonClassName} onClick={handleClick}/>
    </>
  );
}

export default HistoryTable;
