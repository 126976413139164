import { NavLink } from 'react-router-dom';
import "./NavBlock.css"
import {ROUTES} from "../../constant";

function NavBlock({className}) {

  return (
    <>
      <a href={ROUTES.DOCS} target="__blank" className={className}>
        Docs
      </a>
      <NavLink to={ROUTES.FAQ} className={className}>
        FAQ
      </NavLink>
      <NavLink to={ROUTES.TERMS_OF_SERVICE} className={className}>
        Terms of Service
      </NavLink>
    </>
  )
}

export default NavBlock;
