import './Benefits.css';
import decentralizedIcon from "../../images/Decentralized-icon.svg";
import overCollateralizedIcon from "../../images/Over-Collateralized-icon.svg";
import chainAgnosticIcon from "../../images/Chain-Agnostic-icon.svg";


function Benefits() {
  return (
    <section className="benefits">
      <h2 className="benefits__title">
        <span className="benefits__title-colortext"> Over-Collateralized </span>
        Decentralized Stablecoin</h2>
      <ul className="benefits__table">
        <li className="benefits__table-cell">
          <img className="benefits__table-cell-image" alt="Chain-Agnostic" src={chainAgnosticIcon}></img>
          <h3 className="benefits__table-cell-title">Low transaction fees</h3>
          <p className="benefits__table-cell-text">
            Most defi protocols in trading and lending run on high transaction fee blockchains allowing room for slippage.  QiUSD is built on Qi Blockchain offering scalability and low transaction fees to ensure optimal functionality.  Most DEXes leak value to miners through high gas fees, sandwiching and loss-vs-rebalancing which is massively reduced by QI Blockchain.
          </p>
        </li>
        <li className="benefits__table-cell">
          <img className="benefits__table-cell-image" alt="Over-Collateralized" src={overCollateralizedIcon}></img>
          <h3 className="benefits__table-cell-title">Over-Collateralized</h3>
          <p className="benefits__table-cell-text">
            QiUSD is secured by the over-collateralization of multiple mainstream stablecoins to offer users diversification amongst stablecoins (e.g. DAI, USDC, and USDT). The total value of collateralized assets is significantly higher than that of the typical 1:1 dollar ratio in circulation ensuring user confidence and protection against depegging.
          </p>
        </li>
        <li className="benefits__table-cell">
          <img className="benefits__table-cell-image" alt="Decentralized" src={decentralizedIcon}></img>
          <h3 className="benefits__table-cell-title">Decentralized</h3>
          <p className="benefits__table-cell-text">
            The QiUSD protocol aims to provide
            the blockchain industry with the most stable, decentralized, tamper-proof,
            and freeze-free stablecoin system, a perpetual system
            independent from any centralized entity.
          </p>
        </li>
      </ul>
    </section>
  )
}

export default Benefits;
