import "./HistoryTableCell.css";

function HistoryTableCell({element}) {
    const formatDescription = (str) => {
        let result = str.split('%s');
        const amount = `${Number(element.amount).toLocaleString('en-US', {maximumFractionDigits: 0})}$`;

        result.splice(1, 1, amount);

        return result.join('');
    }

    const formatId = (id) => {
        let result = id.substring(0, 9) + '...' + id.substring(id.length - 13);

        return result;
    }

  return (
    <li className="history-table__cell">
      <a href={element.explorer_link} target='_blank' className="history-table__cell-container">
        <h2 className="history-table__cell-title">{formatId(element.txid)}</h2>
        <div className="history-table__cell-arrow"></div>
      </a>
      <p className="history-table__cell-value">{formatDescription(element.description)}</p>
      <p className="history-table__cell-date">{element.processed_at}</p>
    </li>
  )
}

export default HistoryTableCell;
