import './About.css';
import logo from "../../images/about-logo.png"

function About() {
  const handleClick = () => {
    document.querySelector('[id="markets"]').scrollIntoView({behavior: 'smooth'});
  }

  const handleTokenAddressClick = () => {
    window.open('https://mainnet.qiblockchain.online/address/0x95E0Aacc2fA619344A3A21BBcd46C3cf43340884', '_blank')
  }

  return (
    <section className="about">
      <div className="about__container">
        <h1 className="about__title">DECENTRALIZED QiUSD</h1>
          <p className="about__text">
            Bridging the gap between blockchain innovation and traditional finance
          </p>
          <button className="about__button about__button_with-gradient" onClick={handleClick}>Trade</button>
        <div className="about__button-container">
          <button className="about__button about__button_without-gradient" onClick={handleTokenAddressClick}>
            <p className="about__button-text">QiUSD Token Addresses</p>
            <span className="about__button-img about__button-img_type_brackets"></span>
          </button>
          { /*<button className="about__button about__button_without-gradient">
            <p className="about__button-text">Audit Report</p>
            <span className="about__button-img about__button-img_type_magglass"></span>
          </button>*/}
        </div>
      </div>
      <img className="about__image" alt="Logo" src={logo}></img>
    </section>
  )
}

export default About;
