import "./IssuanceHistory.css";
import {NavLink} from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import HistoryTable from "../HistoryTable/HistoryTable";
import useScreenWidth from "../../utils/useScreenWidth";
import SliderSection from "../SliderSection"
import {ROUTES} from "../../constant";

function IssuanceHistory() {
    const size = useScreenWidth();

  return (
    <div className="issuance-history">
      <Header />
        <div className='issuance-history__content__wrapper'>
            <div className="issuance-history__content">
                <NavLink to={ROUTES.HOMEPAGE} className="issuance-history__button">
                    <div className="issuance-history__button-arrow"></div>
                    <p className="issuance-history__button-text">Back</p>
                </NavLink>
                <h1 className="issuance-history__title">Issuance History</h1>
                <p className="issuance-history__subtitle">QiUSD transaction records of the issuance contract,
                    authorized contract, and circulation account (PSM transaction records are not included)</p>
                <HistoryTable />
            </div>
        </div>
      {size.width<576 ? <SliderSection /> : ""}
      <Footer />
    </div>
  );
}

export default IssuanceHistory;
