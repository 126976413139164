import {NavLink} from "react-router-dom";
import SocialNetworks from "../SocialNetworks/SocialNetworks";
import BurgerMenu from "../BurgerMenu";
import NavBlock from "../NavBlock/NavBlock";
import './Header.css'
import logoImage from '../../images/QIUSD.svg';
import logoText from '../../images/logo-text.svg';
import useScreenWidth from "../../utils/useScreenWidth";
import {ROUTES} from "../../constant";

function Header() {
  const size = useScreenWidth();

  return (
    <header className="header">
      {size.width>1025 ?
      <>
      <NavLink to={ROUTES.HOMEPAGE} className="logo">
        <img className="logo__image" alt='site logo' src={logoImage}/>
        <img className="logo__text" alt="site logo" src={logoText}/>
      </NavLink>
      <div className="header__container">
        <NavBlock className="nav-block__link"/>
      </div>
      <SocialNetworks />
      </>
      :
      <div className='headerWrapper'>
        <NavLink to={ROUTES.HOMEPAGE} className="logo__mobile"/>
        <BurgerMenu />
      </div>
      }
    </header>
  )
}

export default Header;
