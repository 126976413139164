import React from 'react';
import ReactModal from 'react-modal';
import {ReactComponent as CloseIcon} from "../../../assets/icons/closeIcon.svg";
import classes from './styles.module.scss';

const Modal = ({data, onClose, isOpen}) => {
    return (
        <ReactModal
            isOpen={isOpen}
            contentLabel="Modal"
            overlayClassName={classes.overlayCustom}
            className={classes.contentCustom}
            onRequestClose={onClose}
            shouldCloseOnOverlayClick
            ariaHideApp={false}
        >
            <div className={classes.wrapper}>
                <div className={classes.headingWrapper}>
                    <div className={classes.headingText}>Collateral Details</div>
                    <button type='button' aria-label="Close" className={classes.closeIcon} onClick={onClose}><CloseIcon/></button>
                </div>
                <div>
                    <div className={classes.reserveHeadingWrapper}>
                        <h3 className={classes.reserveHeading}>Reserve Details</h3>
                        <p className={classes.reserveDescription}>Below are the reserve accounts by QI Network. You can verify the signatures of these accounts.</p>
                    </div>
                    {data &&
                        data.map((net, index) => (
                            <div key={'key_' + index} className={classes.table}>
                                <div className={classes.tableHeading}>
                                    <h3 className={classes.netName}>{net[0]}</h3>
                                </div>
                                {net[1].map((elem, ind) => (
                                    <div key={'key_' + ind} className={classes.tokenWrapper}>
                                        <a href={elem.explorer} target='_blank' className={classes.link}>
                                            <p className="history-table__cell-title">{elem.address}</p>
                                            <div className="history-table__cell-arrow"></div>
                                        </a>
                                        <div className={classes.gridBlock}>
                                            <div className={classes.gridHeading}>
                                                <h5 className={classes.gridHeadingCell}>Token</h5>
                                                <h5 className={classes.gridHeadingCell}>Value</h5>
                                            </div>
                                            {elem.balances.map((item, num) => (
                                                <div key={'key_' + num} className={classes.gridValues}>
                                                    <div className={classes.gridValueCell}>{item.tokenName} ({item.tokenSymbol})</div>
                                                    <div className={classes.gridValueCell}>{`${Number(item.balance).toLocaleString('en-US', {maximumFractionDigits: 0})}`} $</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))
                    }
                </div>
            </div>
        </ReactModal>
    );
};

export default Modal;
