import React from 'react';
import TabItem from "./tabItem";
import classes from './styles.module.scss';

const TabSelect = () => {
    return (
        <div className={classes.wrapper}>
            <TabItem value={'compliance'} defaultChecked={true}/>
            <TabItem value={'benefits'} defaultChecked={false}/>
            <TabItem value={'Use cases'} defaultChecked={false}/>
            <TabItem value={'Advantages'} defaultChecked={false}/>
        </div>
    );
};

export default TabSelect;
