import React from 'react';
import {useRecoilValue} from "recoil";
import TabSelect from "./tabSelect";
import classes from './styles.module.scss';
import {tabSectionState} from "../../store/tabSection";
import Compliance from "./ComplianceSection/Compliance";
import BenefitsSection from "./BenefitsSection";
import UseCasesSection from "./UseCasesSection";
import AdvantagesSection from "./AdvantagesSection";

const SliderSection = () => {
    const activeTab = useRecoilValue(tabSectionState);

    return (
    <div>
        <div className={classes.containerPage}>
            <section className={classes.wrapper}>
                <TabSelect/>
                {activeTab === 'compliance' && <Compliance/>}
                {activeTab === 'benefits' && <BenefitsSection/>}
                {activeTab === 'Use cases' && <UseCasesSection/>}
                {activeTab === 'Advantages' && <AdvantagesSection/>}
            </section>
        </div>
    </div>
    );
};

export default SliderSection;
