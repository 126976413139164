import "./Faq.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import AccordeonItem from "./AccordeonItem";
import {NavLink} from "react-router-dom";
import {ROUTES} from "../../constant";

const Faq = () => {

  return (
    <div className="faq">
        <Header />
        <div className='faq__content'>
            <div className="faq__content__wrapper">
                <NavLink to={ROUTES.HOMEPAGE} className="faq__button">
                    <div className="faq__button-arrow"/>
                    <p className="faq__button-text">Back</p>
                </NavLink>
                <h2 className="faq__title">FAQ</h2>
                <AccordeonItem heading={'Why Stablecoins'}>
                    <p className='faq__text'>Stablecoins are a type of cryptocurrency that are pegged to a stable asset, such as the US dollar or gold, with the goal of minimizing price volatility. This makes them an appealing option for transferring money overseas, as they offer the benefits of cryptocurrency (such as fast, cheap, and secure transactions) while avoiding the price fluctuations that can make traditional cryptocurrencies like Bitcoin and Ethereum difficult to use as a means of payment. In this article, we will explore the advantages of using stablecoins for international money transfers, as well as compare them to other popular remittance solutions.</p>
                </AccordeonItem>
                <AccordeonItem heading={'Advantages of Using Stablecoins for International Money Transfers'}>
                    <h4 className='faq__text'><i>Low fees</i></h4>
                    <p className='faq__text'>One of the major benefits of using stablecoins for international money transfers is the low fees associated with these transactions. Because stablecoins are based on blockchain technology, they can be transferred directly from one party to another without the need for intermediaries, such as banks or money transfer companies. This allows for much lower fees compared to traditional methods, which often charge high fees for international transfers.</p>
                    <h4 className='faq__text'><i>Fast transaction times</i></h4>
                    <p className='faq__text'>Another advantage of stablecoins is the speed at which they can be transferred. Transactions on the blockchain can be completed in a matter of minutes, compared to the days or even weeks that it can take for traditional bank transfers to be completed. This makes stablecoins an ideal option for situations where speed is of the essence, such as paying bills or sending money to loved ones in need.</p>
                    <h4 className='faq__text'><i>Increased security</i></h4>
                    <p className='faq__text'>Stablecoins offer increased security compared to traditional methods of money transfer. Because they are based on blockchain technology, stablecoin transactions are immutable and cannot be altered or reversed. This makes them less susceptible to fraud and other forms of financial crime.</p>
                    <h4 className='faq__text'><i>Greater accessibility</i></h4>
                    <p className='faq__text'>Stablecoins can be accessed by anyone with an internet connection and a stablecoin wallet, making them an ideal option for individuals in areas without access to traditional financial institutions. This is particularly important in developing countries, where access to financial services is often limited.</p>
                    <h4 className='faq__text'><i>Greater privacy</i></h4>
                    <p className='faq__text'>Stablecoins offer greater privacy compared to traditional methods of money transfer. Because they are based on blockchain technology, stablecoin transactions do not require personal identifying information to be attached to them. This makes them an attractive option for individuals who value their privacy and wish to keep their financial transactions private.</p>
                </AccordeonItem>
                <AccordeonItem heading={'Comparing Stablecoins to Other Remittance Solutions'}>
                    <p className='faq__text'>Stablecoins are just one of many options available for transferring money internationally. Some other popular options include:</p>
                    <h4 className='faq__text'><i>Bank transfers</i></h4>
                    <p className='faq__text'>Bank transfers are the most traditional method of sending money overseas. They are often the most expensive option, with fees ranging from a few dollars to several hundred dollars depending on the amount being transferred and the destination country. Bank transfers are also the slowest option, with transfer times ranging from a few days to a week or more.</p>
                    <h4 className='faq__text'><i>Money transfer companies</i></h4>
                    <p className='faq__text'>Money transfer companies, such as Western Union and MoneyGram, offer another option for sending money internationally. These companies often charge lower fees than banks, but they are still more expensive than stablecoins. Transfer times with these companies are also slower than with stablecoins.</p>
                    <h4 className='faq__text'><i>Debit and credit cards</i></h4>
                    <p className='faq__text'>Debit and credit cards can be used to make international purchases or withdraw cash from ATMs abroad. However, these methods can be expensive, with high fees for foreign transactions and ATM withdrawals. They are also less secure than stablecoins, as they can be lost or stolen.</p>
                    <p className='faq__text'>In summary, stablecoins offer a number of advantages for international money transfers, including low fees, fast transaction times, increased security, greater accessibility, and greater privacy. While they may not be the right option for everyone, they are definitely worth considering for individuals looking to send money overseas.</p>
                </AccordeonItem>
                <AccordeonItem heading={'How to add QiUSD token in my custom tokens in Metamask?'}>
                    <p className='faq__text'>Copy the token address.</p>
                    <p className='faq__text'>Go to your account >> assets >> import tokens, and now paste the token address 0x95E0Aacc2fA619344A3A21BBcd46C3cf4334088 in the “Token contract address” field.</p>
                    <p className='faq__text'>It will fetch token symbol and token decimals on its own. Click on Add custom token >> Import token</p>
                </AccordeonItem>
            </div>
        </div>
        <Footer />
    </div>
  );
}

export default Faq;
