import './Psm.css';

function Psm() {
  return (
    <section className="psm">
      <div className="psm__guard">
      <h2 className="psm__title">Built on QI Blockchain</h2>
      <p className="psm__subtitle">
          Most defi protocols in trading and lending run on high transaction fee blockchains allowing room for slippage.  QiUSD is built on Qi Blockchain offering scalability and low transaction fees to ensure optimal functionality.  Most DEXes leak value to miners through high gas fees, sandwiching and loss-vs-rebalancing which is massively reduced by QI Blockchain.
      </p>
      <a href='https://qiblockchain.online' target="_blank" className="psm__button">
        <p className="psm__button-text">Explore</p>
        <span className="psm__button-arrow"></span>
      </a>
      </div>
    </section>
  )
}

export default Psm;
