import './FooterLogo.css';
import logoImage from "../../images/logo-foother.svg";
import logoText from "../../images/foother-text.svg";

function FooterLogo() {

  return (
    <a href='https://qiblockchain.online/' target='_blank' className="footer__logo">
      <img className="footer__logo-image" src={logoImage} alt="logo"></img>
      <img className="footer__logo-text" src={logoText} alt="Blockchain"></img>
    </a>
  )
}

export default FooterLogo;
