import './Compliance.css';

const Compliance = () => {
  return (
    <section className="compliance">
        <div className='compliance__wrapper'>
            <div className="compliance__text-container">
                <p className="compliance__text compliance__text_paragraph_first">
                    Although the custodianship of funds are publicly disclosed accompanied by a third party audit report of the smart contracts to ensure security, all QiUSD purchases are also made via our automated technology KYC/AML partner Swipelux in a regulatory compliant way.  QiUSD is backed by VortX Capital and used in the QI ecosystem with VISA/Mastercard integration.
                </p>
            </div>
        </div>
    </section>
  )
}

export default Compliance;
