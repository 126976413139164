import './Markets.css';
import bakeryswap from "../../images/AMM/ETH\ &\ BSC/bakeryswap.png";
import sdlFinance from "../../images/AMM/Multiple/saddle\ finance\ \(SDL\).png";
import curve from "../../images/AMM/Multiple/curve.png";
import pancakeswap from "../../images/AMM/ETH\ &\ BSC/pancakeswap.png";
import gibx from "../../images/AMM/ETH\ &\ BSC/GIBX.png";
import definix from "../../images/AMM/Klaytn/definix.png"
import digifinex from "../../images/digifinex-icon.svg"
import qidex from "../../images/qidex-icon.svg"


function Markets() {
  return (
    <section id='markets' className="markets">
      <h2 className="markets__title">QiUSD Markets</h2>
      <div className="markets__image">
        <a href="https://www.digifinex.com/en-ww" target='_blank' className="markets_item markets_item_polycat-finance">
            <img className='round-icon' src={digifinex} alt="digifinex"/>
        </a>
        <a href="https://qidex.site/#/" target='_blank' className="markets_item markets_item_bakeryswap">
            <img className='round-icon' src={qidex} alt="qidex"/>
        </a>
        <img className="markets_item markets_item_sushiswap" src={bakeryswap} alt="{akeryswap"/>
        <img className="markets_item markets_item_sdl-finance" src={sdlFinance} alt="sdl finance"/>
        <img className="markets_item markets_item_curve" src={curve} alt="curve"/>
        <img className="markets_item markets_item_pancakeswap" src={pancakeswap} alt="pancakeswap"/>
        <img className="markets_item markets_item_gibx" src={gibx} alt="gibx"/>
        <img className="markets_item markets_item_definix" src={definix} alt="definix"/>
      </div>
    </section>
  )
}

export default Markets;
