import React, {useEffect, useRef, useState} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Filler
} from 'chart.js';
import {Line} from "react-chartjs-2";
import transparencyApi from "../../../services/endpoints/transparency";
import styles from './styles.module.scss';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Filler
);

const Chart = () => {
    const [chartData, setChartData] = useState();
    const [tickData, setTickData] = useState([]);

    const ref = useRef();

    const options = {
        borderColor: '#FF136D',
        scales: {
            x: {
                display: false
            },
            y: {
                display: false
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            filler: {
                propagate: false
            }
        }
    };

    const getRatesList = async () => {
        try {
            const res = await transparencyApi.getChartData();

            const dataTemp = res.data.data;
            const ratesData = [];
            const labelData = [];

            dataTemp.forEach((elem) => {
                ratesData.push(elem.totalSupply)
                labelData.push(elem.createdAt)
            });

            setChartData({
                labels: labelData,
                datasets: [
                    {
                        data: ratesData,
                        fill: true,
                        backgroundColor: (context) => {
                            const ctx = context.chart.ctx;

                            const gradient = ctx.createLinearGradient(0, 10, 0, 150);

                            gradient.addColorStop(0, "rgba(255,19,109,0.2)");
                            gradient.addColorStop(0.35, "rgba(255,19,109,0.1)");
                            gradient.addColorStop(1, "rgba(255,19,109,0)");

                            return gradient;
                        },
                        lineTension: 0.3,
                        pointRadius: 0,
                    }
                ],
            })

        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getRatesList();
    }, []);

    const setChartTickets = () => {
        if (ref.current) {
            const arr = [];
            const ticks = ref.current.scales.y.ticks;
            const ticksSorted = ticks.slice(-5).reverse();

            ticksSorted.forEach((elem) => arr.push(elem.label));

            setTickData(arr);
        }
    }

    useEffect(() => {
        setChartTickets();
    }, [chartData]);

    return (
        <div className={styles.wrapper}>
            <ul className="diagram__values">
                {tickData.length &&
                    tickData.map((elem, index) => (
                        <li key={`key_${  index}`} className="diagram__value">{elem}</li>
                    ))
                }
            </ul>
            <div className={styles.chart}>
                {chartData &&
                    <Line
                        ref={ref}
                        data={chartData}
                        options={options}
                        updateMode='none'
                    />
                }
            </div>
        </div>
    );
};

export default Chart;
